


































import {Component, Mixins, Prop} from 'vue-property-decorator'
import PercentageProgress from '@/components/misc/PercentageProgress.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {Exchange} from '@/model/resource/Exchange'

@Component({
  components: {PercentageProgress},
})
export default class ExchangeCard extends Mixins(MixinScreenSize) {
  @Prop({type: Exchange, required: true}) exchange!: Exchange
  @Prop({type: Boolean}) isBasic?: boolean
  @Prop({type: Boolean}) rowMode?: boolean

  get exchangeCardClass() {
    return {
      'exchange-card--row': this.rowMode,
      'exchange-card--center': this.isBasic,
    }
  }
}
