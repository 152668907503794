















































































import {Component, Mixins, Vue} from 'vue-property-decorator'
import NavbarMenu from '@/components/NavbarMenu.vue'
import AppFooter from '@/components/AppFooter.vue'
import {ExchangeCollection} from '@/model/collection/ExchangeCollection'
import {MetaInfo} from 'vue-meta'
import ExchangeCard from '@/components/cards/ExchangeCard.vue'
import FilterToggle from '@/components/FilterToggle.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import FilterExchange from '@/components/filters/FilterExchange.vue'

@Component({
  components: {
    AppFooter,
    NavbarMenu,
    ExchangeCard,
    FilterToggle,
    FilterExchange,
  },
})
export default class ExchangesView extends Mixins(MixinScreenSize) {
  collection = new ExchangeCollection()

  activatedName: string | null = null

  metaInfo(): MetaInfo {
    return {
      title: this.$translate('metadata.title.exchanges'),
    }
  }

  async created() {
    this.applyCollection()
    await this.populateResources()
  }

  isActivated(name: string) {
    return name === this.activatedName
  }

  deactivate() {
    this.activatedName = null
  }

  applyCollection() {
    this.collection.orderBy = 'order'
    this.collection.asc = true
    this.collection.perPage = 30
  }

  async populateResources() {
    await this.collection.expand()
  }
}
